<template>
    <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
        <div>
            <div v-if="appData">
                <policy-alert :app-data="appData" :is-read-only="isReadOnly" @alertSaved="$emit('alertSaved', $event)"
                    @handleError="$emit('handleError', $event)" />

                <policy-summary :app-data="appData" />

                <instructions :instructions-text="instructions" />

                <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fas fa-file-alt fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Renewal Details</span>
                    </h4>

                    <div class="text-center">
                        <label class="col-form-label font-weight-bold font-size-lg" v-html="declarationStatus"></label>
                    </div>

                    <div v-if="!declarationFinalised">
                        <div class="text-center">
                            <span>
                                <button class="btn btn-outline-primary text-center mt-4 mr-4" @click="$emit('refreshData')"
                                    :disabled="isReadOnly">
                                    <i class="fas fa-sync" title="Refresh"></i> Refresh
                                </button>
                                <button class="btn btn-outline-danger mt-4" @click.prevent="openOverrideConfirmationDialog">
                                    Broker Override
                                </button>

                                <button class="btn btn-outline-primary ml-4 mt-4"
                                    @click.prevent="openLinkInNewTab(appData.transaction.K_DeclarationFormLink)">
                                    View Declaration
                                </button>
                            </span>

                            <b-alert show variant="secondary" class="mt-7 col-5 d-flex justify-content-center mx-auto">
                                <div class="row">
                                    <div class="col-2 text-left d-flex align-items-center">
                                        <i class="fas fa-exclamation-triangle text-dark fa-2xl pl-6"></i>
                                    </div>
                                    <div class="col-10 text-left">
                                        Overriding the Declaration Form process will deactivate the link to the Declaration
                                        Form & submissions using the existing link will no<br> longer be possible.
                                    </div>
                                </div>
                            </b-alert>
                        </div>

                        <div class="text-center">
                            <!-- Hidden input to validate if the declaration has been finalised -->
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="declarationFinalised" v-slot="{ errors }">
                                <input type="hidden" v-model="declarationFinalised" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <br />
                    </div>

                    <div v-if="declarationFinalised && appData?.declarationData">
                        <div v-if="!declarationOverridden">
                            <div class="text-center mt-2">
                                <a :href="declarationFormLink" class="btn btn-primary" target="_blank"><i
                                        class="fa-regular fa-clipboard fa-md mr-2" style="margin-top: -3px;"
                                        title="Select"></i>Review Declaration Form</a>
                            </div>
                            <br />
                        </div>
                        <declaration-form :formData="appData.declarationData.sumInsuredData"
                            :configuration="sumsDataTableConfiguration" :is-read-only="isReadOnly" />

                        <declaration-form :formData="appData.declarationData.excessData"
                            :configuration="excessDataTableConfiguration" :is-read-only="isReadOnly" />

                        <building-properties :form-data="appData.declarationData.assetPropertiesData"
                            :configuration="buildingPropertiesConfiguration" :is-read-only="isReadOnly" />
                    </div>
                </b-card>
            </div>

            <div v-if="declarationFinalised">
                <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                    name="appData.statusFlags.declarationDetailsConfirmed" v-slot="{ errors }">
                    <div class="row mt-10">
                        <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                            <label>
                                <input type="checkbox" :disabled="isReadOnly" v-model="localDeclarationConfirmed"
                                    @change="onConfirmationChange" />
                                <span></span>
                            </label>
                        </span>
                        <label class="col-form-label font-size-h5 ml-2 col-10">
                            I confirm that I have reviewed the declaration details.
                        </label>
                    </div>
                    <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>

            <b-modal v-if="!isReadOnly" ref="overrideConfirmationDialog" title="Confirm Broker Override" size="md"
                no-close-on-esc no-close-on-backdrop hide-header-close @ok="overrideDeclaration"
                @cancel="clearOverrideReason">
                Please enter the reason for overriding the Declaration Form.
                <ValidationProvider rules="required|max:2000" name="appData.brokerOverrideReason" v-slot="{ errors }">
                    <div class="form-group mt-5 min-rows">
                        <b-textarea class="form-control" v-model="overrideReason"></b-textarea>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </b-modal>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";

export default {
    name: "RenewalPage",

    components: {
        PolicySummary,
        PolicyAlert,
        Instructions,
        DeclarationForm,
        BuildingProperties,
    },

    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    async created() {
        if (!this.appData?.declarationData) {
            try {
                const { data } = await this.$renewalDataService.getDeclarationData(this.appData.policy.id);
                this.$set(this.appData, 'declarationData', data.result);
                this.$emit('updateWizardAppDataString');
            } catch (error) {
                this.$emit('handleError', 'Unable to retrieve declaration data');
            }
        }
    },

    computed: {
        formData() {
            return this.appData?.declarationData?.sumInsuredData ?? [];
        },

        instructions: function () {
            return "Please review and confirm the sums insured and excess details below.";
        },

        declarationFinalised: function () {
            let decStatus = _.get(
                this.appData,
                "transaction.K_DeclarationFormStatus"
            );
            return decStatus === "Submitted" || decStatus === "Overridden";
        },

        declarationOverridden: function () {
            let decStatus = _.get(
                this.appData,
                "transaction.K_DeclarationFormStatus"
            );
            return decStatus === "Overridden";
        },

        declarationFormLink: function () {
            let decFormLink = _.get(
                this.appData,
                "transaction.K_DeclarationFormLink"
            );
            if (!decFormLink) {
                decFormLink = _.get(this.appData, "documents.declarationForm.view_url");
            }
            return decFormLink;
        },

        declarationStatus: function () {
            let decOverrideTimestamp = _.get(
                this.appData,
                "transaction.K_DeclarationFormSubmissionOverrideTime"
            );
            let decSubmittedTimestamp = _.get(
                this.appData,
                "transaction.K_DeclarationFormSubmittedTime"
            );

            if (decOverrideTimestamp) {
                decOverrideTimestamp = moment
                    .tz(decOverrideTimestamp, "Australia/Sydney")
                    .format("DD/MM/YYYY hh:mm:ss A");
            }

            if (decSubmittedTimestamp) {
                decSubmittedTimestamp = moment
                    .tz(decSubmittedTimestamp, "Australia/Sydney")
                    .format("DD/MM/YYYY hh:mm:ss A");
            }

            let decStatus = `Awaiting Declaration Form from the Strata contact.`;

            if (this.declarationOverridden && decOverrideTimestamp) {
                let overridingBroker =
                    _.get(this.appData, "transaction.K_OverridingBroker") || "Broker";
                decStatus = `<span class="text-danger">Declaration Form was overridden by ${overridingBroker} on ${decOverrideTimestamp}.</span>`;
                let reason = _.get(this.appData, "transaction.K_OverrideReason");
                if (reason) {
                    decStatus = `${decStatus}<br/>Reason: ${reason}`;
                }
            } else if (decSubmittedTimestamp) {
                let decFormSubmittedBy =
                    _.get(this.appData, "transaction.K_DeclarationFormSubmittedBy") ||
                    "Strata Contact";
                decStatus = `<span class="text-primary">Declaration Form was submitted by ${decFormSubmittedBy} on ${decSubmittedTimestamp}.</span>`;
            }

            return decStatus;
        },

        sumsDataTableConfiguration: function () {
            return {
                title: "Sums Insured",
                currentTitle: "Current Value",
                renewalTitle: "Renewal Value",
            };
        },

        excessDataTableConfiguration: function () {
            return {
                title: "Excesses",
                currentTitle: "Current Value",
                renewalTitle: "Renewal Value",
                showNewValue: true,
            };
        },

        buildingPropertiesConfiguration: function () {
            return {
                title: "Building Properties",
                showCurrentValue: true,
                showNewValue: true,
                currentValueTitle: "Current Value",
                newValueTitle: "New Value",
                allowDeletion: false,
                allowAddition: false,
            };
        },
    },

    data() {
        return {
            overrideReason: null,
            localDeclarationConfirmed: false,
        };
    },

    watch: {
        'appData.statusFlags.declarationDetailsConfirmed': {
            immediate: true,
            handler(newValue) {
                this.localDeclarationConfirmed = newValue;
            },
        },
    },

    methods: {
        openLinkInNewTab(url) {
            if (url) {
                window.open(url, '_blank');
            }
        },

        onConfirmationChange() {
            this.$emit('saveAppData', {
                field: 'statusFlags.declarationDetailsConfirmed',
                value: this.localDeclarationConfirmed,
                updateCallback: (success) => {
                    if (!success) {
                        // Revert the local change if the save failed
                        this.localDeclarationConfirmed = !this.localDeclarationConfirmed;
                    }
                },
            });
        },

        openOverrideConfirmationDialog: function () {
            this.$refs["overrideConfirmationDialog"].show();
        },

        overrideDeclaration: function (bvModalEvt) {
            if (!this.overrideReason) {
                bvModalEvt.preventDefault();
                return;
            }
            this.$emit("overrideDeclaration", this.overrideReason);
        },

        clearOverrideReason: function () {
            this.overrideReason = null;
        },
    },
};
</script>
